
  import { Component, Prop } from 'vue-property-decorator'
  import { fixPrice } from '@/utils/general'
  import { laborData } from '@/store/persons/person'
  import { Details } from '@/utils/generalInterface'
  import { BaseCustomForm } from '@/components/person/BaseAlignment'

@Component({
  methods: {
    fixPrice,
  },
})
  export default class LaborDataContent extends BaseCustomForm {
  @Prop({ type: Array, default: () => [] })
  laborData!: Array<laborData>;

  content (item: Details) {
    const {
      workerType,
      incomeType,
      timeSinceAdmission,
      rent,
      jobTitle,
    } = item

    if (item.idTypeWorker !== 'dependent') {
      return `${workerType} / ${fixPrice(rent)}`
    }

    const contentWorkerType = `${workerType} /`
    const contentIncomeType = incomeType ? ` ${incomeType} /` : ''
    const contentTimeSinceAdmission = ` ${timeSinceAdmission} /`
    const contentRent = ` ${fixPrice(rent)} /`
    const contentJobTitle = jobTitle ? ` ${jobTitle}` : ''

    return `${contentWorkerType}${contentIncomeType}${contentTimeSinceAdmission}${contentRent}${contentJobTitle}`
  }
  }
